<template>

   <div>
         <h5 class=”card-title”>Notification</h5>
   </div>

</template>

<script>
export default {
  name: 'Notification'
}
</script>
