<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    placeholder="Email"
                    autocomplete="username email"
                    v-model="obj.email"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="obj.password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton
                        color="primary"
                        class="px-4"
                        @click.prevent="login"
                        >Login</CButton
                      >
                    </CCol>
                    <CCol col="6" class="text-right">
                      <!-- <CButton color="link" class="px-0"
                        >Forgot password?</CButton
                      >
                      <CButton color="link" class="d-lg-none"
                        >Register now!</CButton
                      > -->
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
              <CCardFooter>
                <p>Download Adnroid APK</p>
                <CLink href="https://github.com/fakhrul/track_and_trace_mobile/releases/download/v1.1/tracks-app-6042d2d6b5434d40b60011cd722572c1-signed.apk" class="card-header-action btn-setting">
                  v 1.1
                </CLink>
                <CLink href="https://github.com/fakhrul/track_and_trace_mobile/releases/download/v1.2/tracks-app-07e28748684f48af83ae3da899fab0d7-signed.apk" class="card-header-action btn-setting">
                  v 1.2
                </CLink>
              </CCardFooter>
            </CCard>
            <!-- <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <CButton color="light" variant="outline" size="lg">
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard> -->
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: () => {
    return {
      obj: {
        // email: "admin@test.com",
        // password: "password",
        email: "",
        password: "",
        authenticated: true,
      },
    };
  },
  mounted() {
    var self = this;
    self.authenticated = auth.check();
    if (self.authenticated) {
      self.$router.push({ path: "/dashboard" });

      // self.$router.push({ path: "/dashboard" });
    }
  },
  methods: {
    login() {
      var self = this;
      let data = {
        email: self.obj.email,
        password: self.obj.password,
      };
      auth.doLogin(data).then((response) => {
        auth.recordLogin(response.token, response.user);
        self.$router.push({ path: "/dashboard" });
        // self.$router.push({ path: "/" });
      });
    },
  },
};
</script>
