<template>

   <div>
         <h5 class=”card-title”>Card One</h5>
   </div>

</template>

<script>
export default {
  name: 'CustomPage'
}
</script>
